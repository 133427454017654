* {
  font-family: 'Poppins', sans-serif;
}

@import url(//db.onlinewebfonts.com/c/cc4a0b8a19e086c6aeca2e3611fe3285?family=Third+Rail+-+Demo);

@font-face {
  font-family: "Third Rail - Demo";
  src: url("//db.onlinewebfonts.com/t/cc4a0b8a19e086c6aeca2e3611fe3285.eot");
  src: url("//db.onlinewebfonts.com/t/cc4a0b8a19e086c6aeca2e3611fe3285.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/cc4a0b8a19e086c6aeca2e3611fe3285.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/cc4a0b8a19e086c6aeca2e3611fe3285.woff") format("woff"), url("//db.onlinewebfonts.com/t/cc4a0b8a19e086c6aeca2e3611fe3285.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/cc4a0b8a19e086c6aeca2e3611fe3285.svg#Third Rail - Demo") format("svg");
}

@import url('https://fonts.cdnfonts.com/css/montserrat');
@import url('https://fonts.cdnfonts.com/css/mrs-monster');

@media screen and (max-width: 499px) {

  .headers2 img{
    display: none;
  }
  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  .tableMain {
    width: 100%;
    padding: 0;
  }

  th {
    padding: 1px;
    text-align: center;
    font-weight: 500;
    font-size: 10px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 10px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 30px;
    padding-top: 30px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: none;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 30%;
    display: none;
    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .mobileNavbar {
    display: flex;
  }

  .headerImg {
    margin-right: 3%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .mobileNavbar {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .headers2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-bottom: 5%;
  }

  .tabs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headerImg {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;

  }

  .headerImg2 {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .mobileNavbar2 {
    margin-top: 5%;
    display: flex;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .soon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnNavbar {
    display: flex;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnNavbar img{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30px;
    height: 30px;
  }

  .headers2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 120px;
    height: 26px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    background-color: #43639400;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: flex-start;
  }

  .imageAndCon img {
    cursor: pointer;
    margin-left: 10%;

  }

  .imageAndCon2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .imageAndCon2-2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .btnNavbar {
    color: white;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .soon {
    margin-left: 10%;
    width: 27px;
    height: 27px;

  }

  .priceDetails {
    margin-bottom: 5%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 25px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 17px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    width: 175px;
    height: 175px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerRef2Div {
    width: 80%;
    background-color: #14182300;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
    flex-flow: column nowrap;
  }

  .bannerStake {
    width: 80%;
    background-color: #141823;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 18px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 15px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 14px;
    margin-top: -3%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: -2%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 16px;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 80px;
    height: 17px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
    font-size: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .socialIcons {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-top: 5%;
    margin-bottom: 3%;

  }

  .socialIcons img {
    margin-left: 2%;
    margin-right: 2%;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 7px;
    font-size: 15px;
    width: 150px;
    border: none;
  }

  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 22px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 25px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 27px;
    height: 21px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 27px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: center;
    padding: 20px;

  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 13px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 18px;
  }

  .amount2 {
    color: #5d6670;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;

  }

  .success img {
    width: 12px;
    height: 9px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;

  }

  .success div {
    color: #86d360;
    display: none;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;

  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
    width: 100px;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 10px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 10px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
  }

  .days1 {
    color: #5c687d;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 13px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 21vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 49px;
    height: 52px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 15px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 10px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 12px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 13.5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 19px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 13px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    border: none;
  }

  .withdrawSection {
    margin-top: 5%;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .btnNavbar img{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 30px;
    height: 30px;
  }

  .p2E{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
     background-color: #106633;
    display: none;
  }

  .headers2 img{
    display: none;
  }
  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 10px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: none;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 30%;
    display: none;
    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .mobileNavbar {
    display: flex;
  }

  .headerImg {
    margin-right: 3%;
    width: 20px;
    height: 20px;
    cursor: pointer;

  }

  .mobileNavbar {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .headers2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-bottom: 5%;
  }

  .tabs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }

  .headerImg {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;

  }

  .mobileNavbar2 {
    margin-top: 5%;
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .soon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .headers2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 170px;
    height: 23px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    background-color: #43639400;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: flex-start;
  }

  .imageAndCon img {
    cursor: pointer;
    margin-left: 10%;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .imageAndCon2-2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .btnNavbar {
    color: white;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
     display: flex;
  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .soon {
    margin-left: 10%;
    width: 27px;
    height: 12px;
  }

  .priceDetails {
    margin-bottom: 5%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 27px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 17px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    width: 175px;
    height: 175px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerRef2Div {
    width: 60%;
    background-color: #14182300;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
    flex-flow: column nowrap;
  }

  .bannerStake {
    width: 80%;
    background-color: #141823;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 18px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 15px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 14px;
    margin-top: -3%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: -2%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 18px;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 100px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
    font-size: 12px;
  }

  .socialIcons {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-top: 5%;
  }

  .socialIcons img {
    margin-left: 2%;
    margin-right: 2%;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 7px;
    font-size: 15px;
    width: 150px;
    border: none;
  }


  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 22px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 25px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 27px;
    height: 21px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 27px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: center;
    padding: 40px;

  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 13px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 23px;
  }

  .amount2 {
    color: #5d6670;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;

  }

  .success img {
    width: 12px;
    height: 9px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;

  }

  .success div {
    color: #86d360;
    display: none;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;

  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
  }

  .days1 {
    color: #5c687d;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 13px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 21vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 49px;
    height: 52px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 15px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 10px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 12px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 13.5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 19px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 13px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    border: none;
  }

  .withdrawSection {
    margin-top: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: none;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 30%;
    display: none;
    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .mobileNavbar {
    display: flex;
  }

  .headerImg {
    margin-right: 3%;
    cursor: pointer;

  }

  .mobileNavbar {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .headers2-2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-bottom: 5%;
  }

  .tabs {
    display: flex;
    flex-flow: row nowrap;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    justify-content: center;
  }

  .headerImg {
    width: 32px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;

  }

  .mobileNavbar2 {
    margin-top: 5%;
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .soon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnNavbar {
    display: flex;
  }

  .headers2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;

  }

  .headers2 img{
    display: none;
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 170px;
    height: 23px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    background-color: #43639400;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: flex-start;
  }

  .imageAndCon img {
    cursor: pointer;
    margin-left: 10%;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .imageAndCon2-2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .btnNavbar {
    color: white;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .soon {
    margin-left: 10%;
    width: 27px;
    height: 12px;
  }

  .priceDetails {
    margin-bottom: 5%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 30px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 17px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    width: 175px;
    height: 175px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerRef2Div {
    width: 60%;
    background-color: #14182300;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
    flex-flow: column nowrap;
  }

  .bannerStake {
    width: 60%;
    background-color: #141823;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 18px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 15px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 14px;
    margin-top: -3%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: -2%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 18px;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 120px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
    font-size: 14px;
  }

  .socialIcons {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    margin-top: 5%;
  }

  .socialIcons img {
    margin-left: 2%;
    margin-right: 2%;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 7px;
    font-size: 15px;
    width: 150px;
    border: none;
  }


  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 25px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 25px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 30px;
    height: 23px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 27px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: center;
    padding: 40px;

  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 15px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 25px;
  }

  .amount2 {
    color: #5d6670;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    background-color: #233a25;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }

  .success img {
    width: 14px;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

  }

  .success div {
    color: #86d360;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;

  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
  }

  .days1 {
    color: #5c687d;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 13px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 21vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 49px;
    height: 52px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 15px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 10px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 12px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 13.5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 19px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 13px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    border: none;
  }

  .withdrawSection {
    margin-top: 5%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .headers2-2 {
    display: none;
  }

  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 25%;

    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 5%;
  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 150px;
    height: 20px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    background-color: #43639400;
    margin-bottom: 30%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: space-between;
  }

  .imageAndCon img {
    cursor: pointer;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: center;
    margin-bottom: 14%;
    font-size: 16px;
  }

  .imageAndCon2-2 {
    display: flex;
    justify-content: center;
    margin-bottom: 14%;
    font-size: 16px;
  }

  .btnNavbar {
    margin-left: 7%;
    color: white;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }

  .soon {
    margin-left: 10%;
    width: 27px;
    height: 12px;
  }

  .priceDetails {
    margin-bottom: 13%;
  }



  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 30px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 17px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    width: 175px;
    height: 175px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerRef2Div {
    width: 70%;
    background-color: #14182300;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .bannerStake {
    width: 80%;
    background-color: #141823;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 18px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 15px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 14px;
    margin-top: -3%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: -2%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 18px;
  }

  .footer {
    width: 75%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 150px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
  }

  .socialIcons {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-top: auto;
    padding-bottom: 10%;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 7px;
    font-size: 15px;
    width: 150px;
    border: none;
  }

  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 25px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 25px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 30px;
    height: 23px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 27px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: flex-start;
    padding: 40px;
  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 15px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 25px;
  }

  .amount2 {
    color: #5d6670;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    background-color: #233a25;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }

  .success img {
    width: 14px;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

  }

  .success div {
    color: #86d360;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;

  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
  }

  .days1 {
    color: #5c687d;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 13px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 21vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 49px;
    height: 52px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 15px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 10px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 12px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 13.5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 19px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 13px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    border: none;
  }

  .withdrawSection {
    margin-top: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  
  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }
  .headers2-2 {
    display: none;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 16px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 25%;

    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers2 {
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 5%;
  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 150px;
    height: 20px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    background-color: #43639400;
    margin-bottom: 30%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: space-between;
  }

  .imageAndCon img {
    cursor: pointer;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: center;
    margin-bottom: 14%;
  }

  .btnNavbar {
    margin-left: 7%;
    color: white;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

  }

  .imageAndCon2 img {
    cursor: pointer;
  }

  .soon {
    margin-left: 10%;
  }

  .priceDetails {
    margin-bottom: 13%;
  }



  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 35px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 20px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    width: 175px;
    height: 175px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerRef2Div {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStake {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 18px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 15px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 14px;
    margin-top: -3%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: -2%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
  }

  .footer {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 150px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
  }

  .socialIcons {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-top: auto;
    padding-bottom: 10%;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 10px;
    font-size: 15px;
    width: 150px;
    border: none;
  }

  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 25px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 25px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 30px;
    height: 23px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 27px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: flex-start;
    padding: 60px;
  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 15px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 25px;
  }

  .amount2 {
    color: #5d6670;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    background-color: #233a25;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }

  .success img {
    width: 14px;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

  }

  .success div {
    color: #86d360;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;

  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
  }

  .days1 {
    color: #5c687d;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 13px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 21vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 49px;
    height: 52px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 13%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 15px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 10px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 12px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 13.5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 19px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 13px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    border: none;
  }

  .withdrawSection {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1920px) {
  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }
  .headers2-2 {
    display: none;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 23px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 20px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 25%;

    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers2 {
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 5%;
  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 200px;
    height: 27px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    background-color: #43639400;
    margin-bottom: 30%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: space-between;
  }

  .imageAndCon img {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14%;
  }

  .p2E{
    cursor: pointer;
    width: 35px;
    height: 35px;    
  }

  .soon{
    cursor: pointer;
    width: 35px;
    height: 16px;    
  }


  .imageAndCon2P2E {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14%;
  }

  .btnNavbar {
    margin-left: 7%;
    color: white;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 21px;
  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 21px;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }

  .soon {
    margin-left: 10%;
    width: 35px;

  }

  .priceDetails {
    margin-bottom: 13%;
  }



  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    font-size: 22px;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 45px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 22px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerStakingPools img{
    width: 350px;
    height: 350px;
  }

  .bannerRef2Div {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStake {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 18px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 22px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 17px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 16px;
    margin-top: -3%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: -1%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 22px;
  }

  .footer {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 180px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
    font-size: 20px;
  }

  .socialIcons {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-top: auto;
    padding-bottom: 10%;
  }

  .socialIcons img{
    width: 27px;
    height: 27px;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 10px;
    font-size: 18px;
    width: 180px;
    border: none;
  }

  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 30px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 30px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 33px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 33px;
    height: 31px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: flex-start;
    padding: 60px;
  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 20px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 33px;
  }

  .amount2 {
    color: #5d6670;
    font-size: 20px;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
    font-size: 23px;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    background-color: #233a25;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }

  .success img {
    width: 14px;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

  }

  .success div {
    color: #86d360;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;
    font-size: 18px;
  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
    font-size: 18px;

  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
    font-size: 17px;
  }


  .days1 {
    color: #5c687d;
    font-size: 17px;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 17px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
    font-size: 20px;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    font-size: 20px;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 14.5vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 49px;
    height: 52px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 13%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 25px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 20px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 20px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;

  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 16px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 17px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    border: none;

  }

  .withdrawBtn{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .withdrawSection {
    margin-top: 10%;
  }
}


@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }
  .headers2-2 {
    display: none;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 30px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 27px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 25%;

    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers2 {
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 5%;
  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 300px;
    height: 40px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    background-color: #43639400;
    margin-bottom: 30%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: space-between;
  }

  .imageAndCon img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14%;
  }

  .btnNavbar {
    margin-left: 7%;
    color: white;
    width: 300px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 30px;
  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 300px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 30px;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }

  .soon {
    margin-left: 10%;
  }

  .priceDetails {
    margin-bottom: 13%;
  }



  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    font-size: 33px;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 60px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 27px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerStakingPools img{
    width: 350px;
    height: 350px;
  }

  .bannerRef2Div {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStake {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 28px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 28px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 25px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 23px;
    margin-top: -1%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: 5%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 28px;
  }

  .footer {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 240px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
    font-size: 30px;
  }

  .socialIcons {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-top: auto;
    padding-bottom: 10%;
  }

  .socialIcons img{
    width: 33px;
    height: 33px;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 10px;
    font-size: 24px;
    width: 230px;
    border: none;
  }

  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 45px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 45px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 45px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 45px;
    height: 42px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: flex-start;
    padding: 60px;
  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 30px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 43px;
  }

  .amount2 {
    color: #5d6670;
    font-size: 30px;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
    font-size: 35px;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    background-color: #233a25;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }

  .success img {
    width: 14px;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

  }

  .success div {
    color: #86d360;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;
    font-size: 22px;
  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
    font-size: 18px;

  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 25px;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
    font-size: 22px;
  }


  .days1 {
    color: #5c687d;
    font-size: 22px;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 23px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
    font-size: 25px;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    font-size: 25px;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 14vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 60px;
    height: 64px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 13%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 25px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 20px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 30px;
    height: 33px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 26px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;

  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 20px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 25px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 140px;
    border: none;

  }

  .withdrawBtn{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .withdrawSection {
    margin-top: 10%;
  }
}

@media screen and (min-width: 3840px) {
  .bannerRef2-2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }
  .headers2-2 {
    display: none;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  th {
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 30px;

  }

  td {
    padding: 8px;
    text-align: center;
    font-size: 27px;
    border-bottom: 1px #1c202b solid;
    padding-bottom: 40px;
    padding-top: 40px;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }


  .submitBtn {
    background-color: #44a06c;
    color: white;
    margin-left: 3%;
    margin-top: 5%;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .buyDetails2 {
    margin-left: 3%;
    padding: 7px;
    margin-top: 2%;

  }

  .stakeWC {
    width: 250px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 5%;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 7%;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    background-color: #95631c;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: #744d15 7px solid;
    border-top: #fab95e 7px solid;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    width: fit-content;
    display: flex;
    margin-right: 3%;
  }

  .btns {
    width: 150px;
    background: linear-gradient(180deg, rgba(136, 146, 233, 1) 11%, rgba(104, 116, 225, 1) 80%);
    border: 3px solid #2a4db1;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
  }

  .btnsDis {
    width: 150px;
    background: linear-gradient(180deg, rgb(148, 157, 235) 11%, rgb(125, 136, 228) 80%);
    border: 3px solid #4363bb;
    color: rgb(102, 102, 102);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;

  }

  .btns2 {
    width: 150px;
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    border: 3px solid #771010;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns2Dis {
    width: 150px;
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);
    border-radius: 10px;
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #ffb963;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border-radius: 25px;
    border: #70512b 7px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .markBarCon {
    background-color: #ffa931;
    display: flex;
    flex-flow: row nowrap;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #694d2c;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #694d2c;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 110px;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background: url('./assets/bbart.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 45%;
    background-color: #CB6015;
    ;
    padding: 35px;
    border-radius: 30px;
    border: white solid 7px;
    margin-left: 1%;
    margin-right: 1%;

  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
    margin-bottom: 5%;
    height: 25%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background: linear-gradient(180deg, rgba(164, 218, 141, 1) 11%, rgba(110, 196, 73, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #106633 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }


  .approveBtns3 {
    background: linear-gradient(180deg, rgba(232, 104, 103, 1) 11%, rgba(214, 61, 61, 1) 80%);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #b22020 solid;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background: linear-gradient(180deg, rgb(231, 126, 126) 11%, rgb(228, 97, 97) 80%);
    border: 4px #ad5555 solid;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg2 {
    width: 120px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-color: #070b17;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    height: 160vh;
    width: 25%;

    background: url('./assets/Container.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers2 {
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 5%;
  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(144deg, #d37d00, #ef8f01 50%, #e1a041);
    border: solid 3px;
    color: rgb(0, 0, 0);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 300px;
    height: 40px;
    cursor: pointer;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    background-color: #43639400;
    margin-bottom: 30%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .section2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .imageAndCon {
    display: flex;
    justify-content: space-between;
  }

  .imageAndCon img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }

  .imageAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14%;
  }

  .btnNavbar {
    margin-left: 7%;
    color: white;
    width: 300px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 30px;
  }

  .onClick {
    margin-left: 7%;
    color: #7e869b;
    width: 300px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 30px;

  }

  .imageAndCon2 img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }

  .soon {
    margin-left: 10%;
  }

  .priceDetails {
    margin-bottom: 13%;
  }



  .imageAndCon div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    font-size: 33px;
  }

  .price {
    color: #007aff;
    font-weight: 700;
    font-size: 60px;
    margin-top: 2%;
  }

  .price2 {
    color: #ffffff;
    font-size: 27px;
  }

  .bannerRef {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .bannerStaking {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .assetsDiv {
    position: relative;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    background-color: #141823;
    border-radius: 30px;
  }

  .bannerRef2 {
    object-fit: contain;
    width: 100%;
    margin-bottom: 2%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
  }

  .bannerRef2 div {}

  .bannerRef2 img {}

  .sectionCon {
    padding: 25px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #141823;
  }

  .imgDiv {
    object-fit: contain;
  }

  .centerImg img {}

  .centerImg {}

  .bannerRef img {
    z-index: 9;
    width: 100%;
  }

  .bannerStaking img {
    z-index: 9;
    width: 100%;
  }

  .bannerStakingPools {
    z-index: 9;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .bannerStakingPools img{
    width: 350px;
    height: 350px;
  }

  .bannerRef2Div {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStake {
    width: 32%;
    background-color: #141823;
    border-radius: 25px;
  }

  .bannerStakeMain2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    height: 80%;
  }

  .bannerStakeMain2Up {
    width: 100%;
    background-color: #171d2c00;
    border-radius: 25px;
    padding: 20px;
    height: 90px;
  }


  .bannerStakeMain2-2 {
    width: 100%;
    background-color: #141823;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 90px;

  }

  .apr {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    font-size: 28px;
    color: white;
  }

  .bannerStake1 {
    color: white;
    font-size: 28px;
    font-weight: 500;
  }

  .bannerStake2 {
    color: white;
    font-size: 25px;
    font-weight: lighter;
  }

  .bannerStake3 {
    color: white;
    font-size: 23px;
    margin-top: -1%;
    font-weight: lighter;
  }

  .bannerStakeBorder {
    width: 100%;
    height: 1px;
    background-color: #5f6a73;
    margin-top: 5%;
  }

  .bannerRef2ImgDiv {
    object-fit: cover;
  }

  .bannerRef2ImgDiv img {
    width: 100%;
  }

  .bannerRef2DivCon {
    padding: 20px;
    background-color: #141823;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    font-size: 28px;
  }

  .footer {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #141823;
    padding: 25px;
    margin-top: 8%;
    margin-left: auto;
  }

  .footer img {
    width: 240px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .copyright {
    color: #aaaaaa;
    text-align: center;
    font-size: 30px;
  }

  .socialIcons {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-top: auto;
    padding-bottom: 10%;
  }

  .socialIcons img{
    width: 33px;
    height: 33px;
  }

  .walletDashboard {
    background-color: #007aff;
    color: white;
    border-radius: 50px;
    padding: 10px;
    font-size: 24px;
    width: 230px;
    border: none;
  }


  .assetsPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .assetsPicAndCon div {
    color: #ff55a4;
    font-size: 45px;
    font-weight: 500;
  }

  .assetsPicAndCon2 div {
    color: #0079fd;
    font-size: 45px;
    font-weight: 500;
  }


  .assetsPicAndCon img {
    width: 45px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .assetsPicAndCon2 img {
    width: 45px;
    height: 42px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .active {
    display: flex;
    justify-content: center;
  }

  .assetsAllCon {
    justify-content: flex-start;
    padding: 60px;
  }

  .assets2Section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }

  .section1Line {
    color: white;
    font-size: 30px;
  }

  .amount {
    color: #0079fd;
    font-weight: 600;
    font-size: 43px;
  }

  .amount2 {
    color: #5d6670;
    font-size: 30px;
  }

  .amount3 {
    color: #92e86c;
    font-weight: 500;
    font-size: 35px;
  }

  .assets2Section1 {
    margin-right: 80px;
  }

  .success {
    display: flex;
    background-color: #233a25;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }

  .success img {
    width: 14px;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

  }

  .success div {
    color: #86d360;
  }

  .maxCon {
    background-color: #394565;
    display: flex;
    justify-content: space-between;
    color: white;
    border: none;
  }

  .maxCon2 {
    background-color: #394565;
    border: none;
    color: white;
    font-size: 22px;
  }

  .maxCon3 {
    background-color: #394565;
    border: none;
    color: white;
    width: 70px;
    font-size: 18px;

  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;

  }

  input {
    padding: 0;
  }

  .maxBtn {
    background-color: #00000000;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 25px;
  }

  .maxCon {
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5%;
  }

  .dayCon {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-top: 5%;
    justify-content: flex-end;
  }

  .dayConDiv {
    color: #6a7891;
    background-color: #394565;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;

  }

  .dayConDiv1 {
    color: #63666d;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  .days {
    color: #6d7b94;
    font-size: 22px;
  }


  .days1 {
    color: #5c687d;
    font-size: 22px;
  }

  .bannerStakeSection3 {
    background-color: #394565;
    margin-top: 5%;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .stakeDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .stakeFont {
    font-size: 23px;
  }

  .approveStake {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    margin-top: 5%;
    font-size: 25px;
  }

  .approveStake2 {
    background-color: #0079fd;
    color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    border-radius: 17px;
    padding: 5px;
    font-weight: 500;
    border: none;
    font-size: 25px;
  }

  .approveSection {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 14vh;
  }

  .stakePicAndCon {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }

  .stakePicAndCon img {
    width: 60px;
    height: 64px;
    margin-right: 12px;
  }

  .stake2Box {
    display: flex;
    justify-content: space-between;
  }

  .stake2Box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 13%;
  }

  .stake2Box1Con {
    display: flex;
  }

  .stake2Box1Con img {
    width: 25px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
    display: flex;
  }

  .stake2Box2 img {
    width: 20px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5px;
  }

  .shareCon {
    display: flex;
  }

  .shareCon img {
    margin-left: 5px;
    width: 30px;
    height: 33px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
  }

  .stake2Box1ConFont {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .stake2Box1ConFont2 {
    font-size: 26px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;

  }

  .maxSelect {
    display: flex;
    justify-content: space-between;
    background-color: #394565;
    color: #717d8c;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 5px;
  }

  .maxSelectNum {
    color: #717d8c;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .maxSelect2 {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakedRGB {
    color: #646f78;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .stakedRGB2 {
    color: #646f78;
    font-size: 20px;
  }

  .selecAndWithdraw {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .withdrawBtn {
    background-color: #84919a;
    font-size: 25px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 140px;
    border: none;

  }

  .withdrawBtn{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .withdrawSection {
    margin-top: 10%;
  }
}